@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    
    /* Font variables */
    --font-fancy: var(--fancy);
    --font-robo: var(--roboto);
    --font-arabic-heading: var(--arabic-heading);
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  html {
    /* overflow-y: scroll; */
  }

  body {
    /* padding-right: calc(100vw - 100%); */
    @apply bg-background text-[#1d1c1c];
  }

  /* Update the heading styles to use different fonts based on language */
  html[lang="ar"] h1, 
  html[lang="ar"] h2, 
  html[lang="ar"] h3, 
  html[lang="ar"] h4, 
  html[lang="ar"] h5, 
  html[lang="ar"] h6,
  html[lang="ar"] li,
  html[lang="ar"] span,
  html[lang="ar"] a {
    font-family: var(--font-arabic-heading);
  }

  html:not([lang="ar"]) h1, 
  html:not([lang="ar"]) h2, 
  html:not([lang="ar"]) h3, 
  html:not([lang="ar"]) h4, 
  html:not([lang="ar"]) h5, 
  html:not([lang="ar"]) h6{
    font-family: var(--fancy);
  }

  html:not([lang="ar"]) p{
    font-family: var(--roboto);
  }


  /* Optional: Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
    display: none;
  }

  /* Optional: Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* For Webkit browsers like Chrome and Safari */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
  * {
    @apply border-border;
  }
}

@layer components {
  .test {
     letter-spacing: 10px;
  }

  .neww {
    font-size: 15px;
  }
}

/* Halloween */
.pumpkin {
	color: #ff6600;
	font-size: 1em;
	font-family: Arial, sans-serif;
	text-shadow: 0 0 5px #000000;
}
@-webkit-keyframes pumpkins-fall {
	0% {
		top: -10%;
	}
	100% {
		top: 100%;
	}
}
@-webkit-keyframes pumpkins-shake {
	0%,
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	50% {
		-webkit-transform: translateX(80px);
		transform: translateX(80px);
	}
}
@keyframes pumpkins-fall {
	0% {
		top: -10%;
	}
	100% {
		top: 100%;
	}
}
@keyframes pumpkins-shake {
	0%,
	100% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(80px);
	}
}
.pumpkin {
	position: fixed;
	top: -10%;
	z-index: 9999;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	-webkit-animation-name: pumpkins-fall, pumpkins-shake;
	-webkit-animation-duration: 10s, 3s;
	-webkit-animation-timing-function: linear, ease-in-out;
	-webkit-animation-iteration-count: infinite, infinite;
	-webkit-animation-play-state: running, running;
	animation-name: pumpkins-fall, pumpkins-shake;
	animation-duration: 10s, 3s;
	animation-timing-function: linear, ease-in-out;
	animation-iteration-count: infinite, infinite;
	animation-play-state: running, running;
}
.pumpkin:nth-of-type(0) {
	left: 1%;
	-webkit-animation-delay: 0s, 0s;
	animation-delay: 0s, 0s;
}
.pumpkin:nth-of-type(1) {
	left: 10%;
	-webkit-animation-delay: 1s, 1s;
	animation-delay: 1s, 1s;
}
.pumpkin:nth-of-type(2) {
	left: 20%;
	-webkit-animation-delay: 6s, 0.5s;
	animation-delay: 6s, 0.5s;
}
.pumpkin:nth-of-type(3) {
	left: 30%;
	-webkit-animation-delay: 4s, 2s;
	animation-delay: 4s, 2s;
}
.pumpkin:nth-of-type(4) {
	left: 40%;
	-webkit-animation-delay: 2s, 2s;
	animation-delay: 2s, 2s;
}
.pumpkin:nth-of-type(5) {
	left: 50%;
	-webkit-animation-delay: 8s, 3s;
	animation-delay: 8s, 3s;
}
.pumpkin:nth-of-type(6) {
	left: 60%;
	-webkit-animation-delay: 6s, 2s;
	animation-delay: 6s, 2s;
}
.pumpkin:nth-of-type(7) {
	left: 70%;
	-webkit-animation-delay: 2.5s, 1s;
	animation-delay: 2.5s, 1s;
}
.pumpkin:nth-of-type(8) {
	left: 80%;
	-webkit-animation-delay: 1s, 0s;
	animation-delay: 1s, 0s;
}
.pumpkin:nth-of-type(9) {
	left: 90%;
	-webkit-animation-delay: 3s, 1.5s;
	animation-delay: 3s, 1.5s;
}
.pumpkin:nth-of-type(10) {
	left: 25%;
	-webkit-animation-delay: 2s, 0s;
	animation-delay: 2s, 0s;
}
